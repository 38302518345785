new Vue({
  el: '#regional',
  data: function() {
    return {
      open: true,
      tabs: {
        selected: 'asia-pacific'
      },
    };
  },
  methods: {
    isSelected(name) {
      if (name === this.tabs.selected) {
        return 'bg-white font-normal border dark:bg-black dark:border-white';
      }else{
        return 'dark:border-white dark:text-white'
      }
    },
    toggleTab(name) {
      this.tabs.selected = name;
    },
    toggleOpen() {
      this.open = !this.open;
    }
  },
});